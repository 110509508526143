.bg-img-1 {
    background-image: url("../images/splash/curtin.jpg");
}

.bg-img-2 {
    background-image: url("../images/splash/curtin2.jpg");
}

.bg-img-3 {
    background-image: url("../images/splash/ritzpool.jpg");
}

.carousel-image {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.footer {
    border-top: 5px solid #7ca656;
    background-color: #313131;
    color: #fff;
    padding-top: 20px;
}

.footer1 {
    border-top: 5px solid #7ca656;
}
