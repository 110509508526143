.admin-image {
position: relative;
text-align: center;
color: white;
padding: 120px;
}

.admin-image-txt {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}