.prod_img_view {
    float: left;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    text-align: center;
    cursor: grab;
}

.prod_img_view .mask,
.prod_img_view .content {
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0
}

.prod_img_view img {
    display: block;
    position: relative;
    width: 100%;
}

.prod_img_view h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 25px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0
}

.prod_img_view h5 {
    font-family: "Oswald", serif;
    font-style: italic;
    font-size: 15px;
    font-weight: 100;
    position: absolute;
    color: #fff;
    padding: 10px 20px 20px;
    text-align: left;
    top: 22%;
    left: 5%
}

.prod_img_view p {
    font-family: "Oswald", serif;
    font-style: bold;
    font-size: 25px;
    font-weight: 100;
    position: absolute;
    color: #fff;
    padding: 10px 20px 20px;
    text-align: left;
    top: 5%;
    left: 5%
}

.prod_img_view a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 0 1px #000
}

.prod_img_view a.info:hover {
    box-shadow: 0 0 5px #000
}

.prod_img_view .mask-1,
.prod_img_view .mask-2 {
    background-color: rgba(0, 0, 0, 0.5);
    height: 180%;
    width: 60%;
    background: #2d5b46;
    opacity: .8;
    transition: all 0.3s ease-in-out 0.6s;
}

.prod_img_view .mask-1 {
    left: auto;
    right: 0px;
    transform: rotate(56.5deg) translateX(-100%);
    transform-origin: 100% 0%;
}

.prod_img_view .mask-2 {
    top: auto;
    bottom: 0px;
    transform: rotate(56.5deg) translateX(100%);
    transform-origin: 0% 100%;
}

.prod_img_view .content {
    background: rgba(0, 0, 0, 0.9);
    height: 0px;
    opacity: 0.5;
    width: 100%;
    top: 0px;
    overflow: hidden;
    transform: rotate(-33.5deg) translate(-112px, 166px);
    transform-origin: 0% 100%;
    transition: all 0.4s ease-in-out 0.3s;
}

.prod_img_view h2 {
    background: transparent;
    margin-top: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.prod_img_view a.info {
    display: none;
}

.prod_img_view:hover .content {
    height: 40%;
    width: 100%;
    opacity: 0.9;
    top: 50%;
    transform: rotate(0deg) translate(0px, 0px);
}

.prod_img_view:hover .mask-1,
.prod_img_view:hover .mask-2 {
    transition-delay: 0s;
}

.prod_img_view:hover .mask-1 {
    transform: rotate(56.5deg) translateX(1px);
}

.prod_img_view:hover .mask-2 {
    transform: rotate(56.5deg) translateX(-1px);
}

.modal-90w {
    min-width: 90vw;
}