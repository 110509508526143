.img-round {
    border-radius: 5%;
}
.img-hover {
    background: linear-gradient(#2d5b46, transparent);
    border-radius: 5%;
    cursor: pointer;
}

.img-hover figure img {
    opacity: 1;
    -webkit-transition: .3s ease-in-out;
    transition: .5s ease-in-out;
    border-radius: 5%;

}

.img-hover:hover img {
    opacity: .1;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.txt-over {
    position: absolute;
    top: 0;
    left: 0;
    size: auto;
}

.txt-over-admin {
    position: absolute;
    top: 10%;
    left: -50%;
    size: auto;
}

.txt-over:hover {
    opacity: 1;
    -webkit-transition: .3s ease-in-out;
    transition: .5s ease-in-out;
    border-radius: 5%;
}