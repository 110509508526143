/* .bg-grey {
    background-color: rgb(233, 233, 233);
} */

.lgreen {
    color: #7ca656;
}
.bg-lgreen {
    background-color: #7ca656;
    color: #ffffff
}

.dgreen {
    color: #2d5b46;
}

.icon-max {
    font-size: 100px;
}

.error {
    color: red;
}


.bgdgreen {
    background-color: #2d5b46;
}

.bglgreen {
    background-color: #7ca656;
}

.bgwhite {
    background-color: #ffffff;
}

.altgreen {
    background-color: #126547;
}

.gray {
    background-color: dimgray;
}
