.missiondiv {
    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%); 
}

.supplynationdiv {
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
}

@media screen and (max-width: 992px){
    .missiondiv {
        clip-path: none;
    }
    .supplynationdiv {
        clip-path: none;
    }
    .h1small {
        font-size: 1em;
    }
    p {
        font-size: 14px;
    }
}