.hd-container {
    /* background-color: transparent; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    position: fixed;
    /* height: 112.5px; */
}

.hd-fade {
    height:112.5px;
    transition:all 1s;
}

.hd-tiny {
    height:52px !important;
    transition:all 1s;
}

.logo-big {
    height:110px;
    transition:all 1s;
}
.logo-tiny {
    height:50px;
    transition:all 1s;
}



.hd-flex {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
}

.hd-test {
    background-color: rgb(40,40,40, .7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    /* height: 112.5px; */
    position: fixed;
}

.hd-projects {
    background-color: black;
    height: 40vh;
    background-size: cover;
    /* border-bottom: 5px solid #7ca656; */
    background-position: bottom;
}

.hd-admin {
    height: 150px;
    border-bottom: 5px solid #7ca656;
}


@media screen and (max-width: 768px) {
    .hd-logo {
        text-align: center;
        padding: 30px 0px 0px 0px !important;
    }

    .hd-flex {
        align-items: center;
        justify-content: center;
    }

}

@media screen and (max-width: 992px) {
    .hd-logo {
        font-size: 13px;
        padding: 0px 0px 0px 0px !important;
        text-align: center;
    }

    .hd-fade {
        height: 150px;
    }

    .hd-tiny {
        height:80px !important;
        transition:all 1s;
    }

    .hd-flex {
        align-items: center;
        justify-content: center;
    }

    .hd-admin {
        height: 20vh;
        border-bottom: 0px;
    }
}