.txt-link {
    font-family: "Oswald", serif;
    font-size: 1.1em;
    font-weight:500;
    text-align: bottom;
    padding-right: 40px;
    height: 19.5px;
    color: white;
    text-decoration: none !important;
    cursor: pointer;
}
.txt-link-footer {
    text-align: bottom;
    font-weight: 300;
    color: white;
    text-decoration: none !important;
    text-decoration: underline !important;
    cursor: pointer;
}
.txt-footer p {
    font-size: .8em;
    padding-bottom: 0;
    margin-bottom: 0;
}

.txt-large {
    font-size: 1.1em;
}

.txt-small {
    font-size: .65em;
}

.txt-oswald {
    font-family: "Oswald", serif;
}

a.txt-link {
    margin: 20px 0 0 0;
    text-align: right;
    text-decoration: none !important;
}

.admin-image-txt{
    text-decoration: none !important;
    color: white;
}
a.admin-image-txt {
    text-decoration: none !important;
    color: white;
}

a:hover {
    color: white;
    text-decoration: none !important;
    cursor: pointer;
}

.txt-project p {
    font-family: "Muli", serif;
    font-size: 1em;
    font-weight: 600;
    white-space: pre-wrap;
}

h1 {
    font-family: "Oswald", serif;
    font-size: 3em;
    font-weight: 300;
}

h2 {
    font-family: "Oswald", serif;
    font-size: 2em;
    font-weight: 300;
}

h3 {
    font-family: "Oswald", serif;
    font-size: 2em;
    font-weight: 300;
}

h4 {
    font-family: "Oswald", serif;
    font-size: 2em;
    font-weight: 300;
    color: white;
}

p {
    font-size: 14px;
}

.txt-white {
    color: white;
}


@media screen and (max-width: 768px) {
    .txt-link {
        position: relative;
        text-align: center;
        font-size: 11px;
    }

    h1 {
        font-size: 1.5em;
    }

    p {
        font-size: .75em;
    }
}